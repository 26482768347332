class AField {
  constructor(element) {
    // elements
    this.element = element;
    this.charCountElement = element.querySelector('.a-field__char-count');
    this.inputElement = element.querySelector('.a-field__input');
    this.errorElement = element.querySelector('.a-field__error');

    // variables
    this.defaultClassName = element.className;

    // events
    this.inputElement?.addEventListener('automaticInput', () => {
      if (this.element.className !== this.defaultClassName) {
        this.element.className = this.defaultClassName;
      }
      this.toggleModifiers();
    });
    this.inputElement?.addEventListener('input', this.onInput.bind(this));

    // init
    this.toggleModifiers();
  }

  get value() {
    return this.inputElement.value;
  }

  onInput() {
    this.toggleModifiers();

    if (this.charCountElement) {
      this.updateCharCount();
    }
    if (this.inputElement.classList.contains('-autosize')) {
      this.autosize();
    }
  }

  updateCharCount() {
    const { inputElement, charCountElement } = this;
    const { maxLength } = this.inputElement;
    const valueLength = inputElement.value.length;
    if (maxLength) {
      charCountElement.innerText = `${valueLength}/${maxLength}`;
    } else {
      charCountElement.innerText = valueLength;
    }
  }

  autosize() {
    const { inputElement } = this;
    inputElement.style.blockSize = 'auto';
    const blockSize = 2 + inputElement.scrollHeight;
    inputElement.style.blockSize = `${blockSize}px`;
  }

  showError(message) {
    this.errorElement.innerText = message;
    this.errorElement.removeAttribute('hidden');

    /* eslint-disable-next-line no-underscore-dangle */
    window._paq?.push(['trackEvent', 'a-field', 'error', message]);
  }

  clearErrors() {
    this.errorElement.innerText = '';
    this.errorElement.setAttribute('hidden', '');
  }

  toggleModifiers() {
    this.element.classList.toggle('-not-empty', this.value !== '');
  }
}

document.querySelectorAll('.a-field:not(.-location, .-checkbox)').forEach((element) => new AField(element));

export default AField;
